import React from 'react';
import { Button } from '@mui/material';
import { Download } from 'lucide-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const ExportButtons = ({ scaffolds }) => {
    const exportCSV = () => {

        const headers = [
            'Scaffold ID',
            'Scaffold Type',
            'Position',
            'Build Date',
            'Dismantle Date',
            'Days',
            'Size/Volume',
            'Daily Price',
            'Total Price'
        ];

        const rows = scaffolds.map(row => {
            const days = calculateDaysDifference(row.buildDate, row.dismantleDate);
            const totalPrice = calculateTotalPrice(row.dailyPrice, row.size, days);

            return [
                row.id,
                row.type,
                row.position,
                row.buildDate,
                row.dismantleDate,
                `${days} days`,
                row.size,
                `$ ${row.dailyPrice.toFixed(2)}`,
                `$ ${totalPrice.toFixed(2)}`
            ];
        });

        // Add total row
        const grandTotal = scaffolds.reduce((total, row) => {
            const days = calculateDaysDifference(row.buildDate, row.dismantleDate);
            return total + calculateTotalPrice(row.dailyPrice, row.size, days);
        }, 0);

        rows.push(['', '', '', '', '', '', '', 'Total:', `$ ${grandTotal.toFixed(2)}`]);

        // Convert to CSV string
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'scaffold_rent_periods.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text('Rent Period Selection', 14, 15);

        // Prepare table data
        const headers = [
            ['ID', 'Type', 'Position', 'Build Date', 'Dismantle Date', 'Days', 'Size', 'Daily Price', 'Total Price']
        ];

        const rows = scaffolds.map(row => {
            const days = calculateDaysDifference(row.buildDate, row.dismantleDate);
            const totalPrice = calculateTotalPrice(row.dailyPrice, row.size, days);

            return [
                row.id,
                row.type,
                row.position,
                row.buildDate,
                row.dismantleDate,
                `${days} days`,
                row.size,
                `$ ${row.dailyPrice.toFixed(2)}`,
                `$ ${totalPrice.toFixed(2)}`
            ];
        });

        // Add total row
        const grandTotal = scaffolds.reduce((total, row) => {
            const days = calculateDaysDifference(row.buildDate, row.dismantleDate);
            return total + calculateTotalPrice(row.dailyPrice, row.size, days);
        }, 0);

        rows.push(['', '', '', '', '', '', '', 'Total:', `$ ${grandTotal.toFixed(2)}`]);

        // Add table to document
        doc.autoTable({
            head: headers,
            body: rows,
            startY: 25,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [69, 69, 69] },
            alternateRowStyles: { fillColor: [245, 245, 245] },
            margin: { top: 25 }
        });

        // Save PDF
        doc.save('scaffold_rent_periods.pdf');
    };

    // Helper functions
    const calculateDaysDifference = (buildDate, dismantleDate) => {
        if (!buildDate) return 0;
        const start = new Date(buildDate);
        const end = dismantleDate && dismantleDate !== '(Not entered yet)'
            ? new Date(dismantleDate)
            : new Date();
        const diffTime = Math.abs(end - start);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const calculateTotalPrice = (dailyPrice, size, days) => {
        const extractNumber = (str) => {
            const match = String(str).match(/[\d.]+/);
            return match ? parseFloat(match[0]) : 0;
        };
        const numericSize = extractNumber(size);
        return dailyPrice * numericSize * days;
    };

    return (
        <div className="flex gap-4 justify-end p-4">
            <Button
                variant="outline"
                onClick={exportCSV}
                className="flex items-center gap-2"
            >
                <Download className="h-4 w-4" />
                Export CSV
            </Button>
            <Button
                onClick={exportPDF}
                className="flex items-center gap-2"
            >
                <Download className="h-4 w-4" />
                Export PDF
            </Button>
        </div>
    );
};