export const UNIT_TYPES = ['LM', 'HM', 'm²', 'm³', 'Volume', 'Rent'];

export const INITIAL_PRICE_STATE = {
  LM: '',
  HM: '',
  'm²': '',
  'm³': '',
  'Volume': '',
  'Rent': ''
};
